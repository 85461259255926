<template>
  <div class="white black--text">
    <!-- <div class="black--text">
      XS: {{ $vuetify.breakpoint.xs }} <br />
      SM: {{ $vuetify.breakpoint.sm }} <br />
      MD: {{ $vuetify.breakpoint.md }} <br />
      LG: {{ $vuetify.breakpoint.lg }} <br />
      XL: {{ $vuetify.breakpoint.xl }} <br />
    </div> -->
    <v-row>
      <v-col
        cols="12"
        offset-md="0"
        md="12"
        offset-lg="1"
        lg="10"
        offset-xl="3"
        xl="6"
                class="mt-12"
      >
        <div v-bind:style="{ 'margin-bottom': under_headings_padding }">
          <heading1Section
            color="black"
            :dense="true"
            :text="$t('lp_h1_full_features')"
          />
          <heading2Section
            color="black"
            :dense="true"
            :text="$t('lp_h2_full_features')"
          />
        </div>

        <v-row no-gutters class="mx-0 d-flex justify-center">
          <v-col
            v-for="feature in full_features"
            :key="feature.img_path"
            cols="6"
            lg="3"
            xl="5"
          >
            <appFeature
              :img_path="feature.img_path"
              :img_alt="feature.img_alt"
              back_color="#dedede40"
              :front_text="feature.front_text"
              :back_title="feature.back_title"
              :back_text="feature.back_text"
              :analytics_event="feature.analytics_event"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <seeAlso v-if="!wait_seeAlsoBtns" :back_section="back_section" />
    <btnBackHome :back_section="back_section" />
    <!-- <div style="height:600px;"></div> -->
    <!-- <pageFooter height="120px" /> -->
  </div>
</template>

<script>
import heading1Section from "@/components/landings/heading1Section.vue";
import heading2Section from "@/components/landings/heading2Section.vue";
import sectionDescription from "@/components/landings/sectionDescription.vue";
import paragraph from "@/components/landings/paragraph.vue";
import seeAlso from "@/components/landings/blocks/seeAlso.vue";
import btnBackHome from "@/components/landings/btnBackHome.vue";
import cardAsButton from "@/components/landings/cardAsButton.vue";
import appOnMobilePhone from "@/components/landings/appOnMobilePhone.vue";
import "@/styles/landing.css";
import AOS from "aos";
import "aos/dist/aos.css";
// import { features } from "@/views/landings/home_features.js";
import appFeature from "@/components/landings/blocks/appFeature.vue";
import full_features from "@/mixins/app_features";
import pageFooter from "@/components/landings/blocks/pageFooter.vue";

export default {
  metaInfo: {
    title: "Caratteristiche e funzionalità di SafetyFirst",
  },
  mixins: [full_features],

  props: ["from_section"],
  components: {
    appFeature,
    //
    heading1Section,
    heading2Section,
    sectionDescription,
    cardAsButton,
    appOnMobilePhone,
    paragraph,
    seeAlso,
    btnBackHome,
    pageFooter,
  },
  data: () => ({
    back_section: null,
    wait_seeAlsoBtns: true,
  }),
  methods: {
    getTranslations(key) {
      return this.$t(key);
    },
  },
  mounted() {
    console.log('features');
    AOS.init();
    if (this.$route.params.from_section) {
      this.back_section = this.$route.params.from_section;
      console.log("back to:", this.$route.params.from_section);
    }
    setInterval(() => {
      this.wait_seeAlsoBtns = false;
    }, 80);
  },
  created() {},
  computed: {
    under_headings_padding() {
      if (this.$vuetify.breakpoint.xs) {
        return "1rem";
      } else if (this.$vuetify.breakpoint.sm) {
        return "1.5rem";
      } else if (this.$vuetify.breakpoint.md) {
        return "2rem";
      } else if (this.$vuetify.breakpoint.lg) {
        return "3rem";
      } else if (this.$vuetify.breakpoint.xl) {
        return "5rem";
      }
    },
  },
  watch: {},
};
</script>

